@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}
.html5 {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/html5.png);
}
.css3 {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/css3.png);
}
.sass {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/sass.png);
}
.js {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/javascript.png);
}
.redux {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/redux.png);
}

.ts {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/typescript.png);
}
.react {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/reactjs.png);
}
.mui {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/material-ui.png);
}
.npm {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/npm.png);
}
.firebase {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/firebase.png);
}
.nextjs {
  width: 42px;
  height: 44px;
  background-size: contain;
  background-image: url(../../assets/images/nextjs.png);
}
